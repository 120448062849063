
import _get from "lodash/get"
import _find from "lodash/find"
import _includes from "lodash/includes"
import _map from "lodash/map"
import _filter from "lodash/filter"
import withLocation from "../containers/withLocation"
import useOverview from "../hooks/use-overview"

const FilteredCitiesProvider = withLocation(({ cities, location, search, children }) => {
  const overview = useOverview()
  const filterType = _get(search, "filterType")
  const filter = _get(search, "filter")

  let filteredCities = cities

  if (filterType === "population") {
    const populationRanges = _get(overview, "population.chunks")
    const chunkIndex = parseInt(filter, 10)
    if (chunkIndex || chunkIndex === 0) {
      const targetChunk = populationRanges[chunkIndex]
      const min = targetChunk[0]
      const max = targetChunk[targetChunk.length - 1]
      filteredCities = _filter(cities, city => city.summary.population >= min && city.summary.population <= max)
    }
  } else {
    const selected = _find(_get(overview, `${filterType}.all`), policy => policy.id === filter)
    const selectedCities = _map(_get(selected, "cities"), ({ slug }) => slug)
    if(selected && selectedCities) {
      filteredCities = _filter(cities, city => _includes(selectedCities, city.slug) )
    }
  }
  
  return children && typeof (children) === "function" ? children({ filteredCities, filter, filterType }) : null
})

export default FilteredCitiesProvider
