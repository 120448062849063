import React from "react"
import styled from "styled-components"

const ClusterPin = styled.div`
  position: relative;
  .inner {
    position: absolute;
    top: -${({ size }) => size / 2}px;
    left: -${({ size }) => size / 2}px;
    color: white;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: ${({ size }) => size / 2}px;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
    font-size: 1rem;
    line-height: ${({ size }) => size}px;
    font-weight: bold;
    letter-spacing: normal;
    vertical-align: middle;
    background: ${({ theme }) => theme.grey1};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;

    .map-filter--policies & {
      background: ${({ theme }) => theme.purple};
    }

    .map-filter--skills & {
      background: ${({ theme }) => theme.greenBlue};
    }

    .map-filter--activities & {
      background: ${({ theme }) => theme.darkBlue};
    }

    .map-filter--population & {
      background: ${({ theme }) => theme.pink};
    }
  }
`

export default ({ superCluster, cluster, handleClick = () => {} }) => {
  const count = cluster.properties.point_count
  return (
    <ClusterPin size={count > 5 ? Math.min(35 + count, 70) : 35} onClick={() => handleClick(cluster, superCluster)}>
      <div className="inner">
        {count}
      </div>
    </ClusterPin>
  )
}
