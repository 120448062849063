import React from "react"
import TrackVisibility from "react-on-screen"
import styled from "styled-components"
import LocationPin from "./styled-components/LocationPin"
import { getStaticMapImageUrl } from "../utils"

const CityCard = ({
  title,
  display_name,
  country_name,
  population,
  latitude,
  longitude,
}) => {
  const lat = latitude || null
  const lon = longitude || null
  const mapImageUrl = lat && lon 
    ? getStaticMapImageUrl({ latitude: lat, longitude: lon, zoom: "1.75", width: 180, height: 180 })
    : null
  return (
    <CityCardWrapper className="city-card">
      <div className="city-card__inner">
        <div className="city-card__content">
          <div className="city-card__title">{display_name || title}</div>
          <p className="city-card__country">{country_name || ""}</p>
          <TrackVisibility once partialVisibility>
            {({ isVisible }) => (
              <div className="city-card__map-container">
                {mapImageUrl ? (
                  <div className="city-card__map" style={{
                    backgroundImage: isVisible ? `url(${mapImageUrl})` : "none",
                  }}>
                    <LocationPin className="location-pin" />
                  </div>
                ) : (
                    <div className="city-card__map" />
                  )}
              </div>
            )}
          </TrackVisibility>
          
          <p className="city-card__population">
            {(population && population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
          </p>
        </div>
      </div>
    </CityCardWrapper>
  )
}

const CityCardWrapper = styled.div`
  &, .city-card {
    position: relative;
    height: 0;
    padding-top: 100%;

    &__inner {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 12px;
      box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.06);
      background: white;
      transition: box-shadow 0.15s ease;
    }

    &:hover {
      .city-card__inner {
        box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.24);
      }
    }

    &:active {
      .city-card__inner {
        box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.02);
      }
    }

    &__title {
      font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.17;
      color: ${({ theme }) => theme.grey1};
      margin-bottom: 0.25rem;
    }

    &__map-container {
      position: relative;
      width: 50%;
      height: 0;
      padding-top: 50%;
      margin: 0 auto 1rem;
    }

    &__map {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid #b6e2e2;
      border-radius: 50%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      .location-pin {
        position: absolute;
        top: calc(50% - 36px);
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &__country {
      display: inline-block;
      font-size: 1rem;
      line-height: 1.25;
      letter-spacing: normal;
      font-family: ${({ theme }) => theme.fonts["vollkorn"]};
      margin-bottom: 0.75rem;
      min-height: 1.25rem;
    }

    &__population {
      font-weight: bold;
      color: ${({ theme }) => theme.grey1};
      min-height: 1.4375rem;
    }
  }  
`

export default CityCard
