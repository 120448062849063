import React from "react"
import styled from "styled-components"
import get from "lodash/get"
import { Link } from "gatsby"

const CityMapPopup = ({ city }) => {
  if(!city) return null
  const { slug, title, summary = {} } = city
  const { country_name, population } = summary
  const policies = [...(get(city, "section_policies.priority_policies") || []), ...(get(city, "section_policies.priority_policies_other") || [])]
    .filter(item => item && item !== "")
  const skills = [...(get(city, "section_staff.skills") || []), ...(get(city, "section_staff.skills_other") || [])]
    .filter(item => item && item !== "")
  const activities = [...(get(city, "section_activities.activities") || []), ...(get(city, "section_activities.activities_other") || [])]
    .filter(item => item && item !== "")
  return (
    <Wrapper>
      <Link to={`/cities/${slug}`}>
        <div className="inner">
          <div className="section details">
            
            <div className="text">
              <p className="name">{title}</p>
              <p className="country">{country_name || ""}</p>
              <p className="population">
                {(population && population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
              </p>
            </div>
            
          </div>
          {policies.length > 0 && (
            <div className="section policies">
              <div className="header">
                <span>Policy Areas</span>
              </div>
              <div className="text">
                <ul>
                  {policies.slice(0, 2).map((policy, i) => (<li key={`policy-${i}`}>{policy}</li>))}
                </ul>
                {policies.length - 2 > 0 && (<span className="more">+ {policies.length - 2} more</span>)}
              </div>
            </div>
          )}
          {skills.length > 0 && (
            <div className="section skills">
              <div className="header">
                <span>Innovation Roles</span>
              </div>
              <div className="text">
                <ul>
                  {skills.slice(0, 2).map((skill, i) => (<li key={`skill-${i}`}>{skill}</li>))}
                </ul>
                {skills.length - 2 > 0 && (<span className="more">+ {skills.length - 2} more</span>)}
              </div>
            </div>
          )}
          {activities.length > 0 && (
            <div className="section activities">
              <div className="header">
                <span>Innovation Activities</span>
              </div>
              <div className="text">
                <ul>
                  {activities.slice(0, 2).map((activity, i) => (
                    <li key={`activity-${i}`}>
                      <span>{activity}</span>
                    </li>
                  ))}
                </ul>
                {activities.length - 2 > 0 && (<span className="more">+ {activities.length - 2} more</span>)}
              </div>
            </div>
          )}
        </div>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.3);
  overflow: hidden;

  .mapboxgl-popup-anchor-top-right & {
    border-top-right-radius: 0;
  }

  .mapboxgl-popup-anchor-top-left & {
    border-top-left-radius: 0;
  }

  .mapboxgl-popup-anchor-bottom-left & {
    border-bottom-left-radius: 0;
  }

  .mapboxgl-popup-anchor-bottom-right & {
    border-bottom-right-radius: 0;
  }

  a {
    text-decoration: none;
  }

  .inner {
    display: flex;
    height: 100%;

    .section {
      min-height: 130px;
      height: 100%;
      border-left: 1px solid rgba(201, 211, 217, 0.8);

      &:first-child {
        border: none;
      }
    
      .text {
        padding: 0 15px;
        margin-bottom: 15px;
      }

      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 40px;
        color: white;
        font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.3px;
        padding: 0 15px;
        margin: 0 -1px;
        background: ${({ theme }) => theme.purple};
        white-space: nowrap;

        span {
          display: inline-block;
        }
      }

      &.details {
        min-width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .text {
          padding: 0 1.375rem;
          margin-bottom: 0;

          .name {
            font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
            font-size: 1.25rem;
            font-weight: bold;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.34px;
            color: ${({ theme }) => theme.grey1};
          }

          .country {
            font-family: ${({ theme }) => theme.fonts['vollkorn']};
            font-size: 0.875rem;
            line-height: 2.86;
            letter-spacing: -0.09px;
            color: ${({ theme }) => theme.grey1};
            white-space: nowrap;
          }

          .population {
            font-family: ${({ theme }) => theme.fonts['roboto']};
            font-size: 0.75rem;
            font-weight: bold;
            line-height: normal;
            letter-spacing: normal;
            color: ${({ theme }) => theme.grey1};
          }
        }
      }

      &.policies {
        .header {
          background: ${({ theme }) => theme.purple};
        }
      }

      &.skills {
        .header {
          background: ${({ theme }) => theme.greenBlue};
        }
      }

      &.activities {
        .header {
          background: ${({ theme }) => theme.darkBlue};
        }
      }

      &.activities, &.skills, &.policies {
        display: none;

        @media (min-width: ${({ theme }) => theme.desktop}) {
          display: block;
        }
      }

      ul {
        list-style: disc;
        margin-top: 12px;
        margin-left: 1rem;

        li {
          font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
          font-size: 12px;
          font-weight: bold;
          color: ${({ theme }) => theme.grey1};
          letter-spacing: normal;

          span {
            display: block
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .more {
        font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
        font-size: 12px;
        color: ${({ theme }) => theme.grey1};
      }
    }
  }
`

export default CityMapPopup
