import React, { useState } from "react"
import styled from "styled-components"
import get from "lodash/get"
import map from "lodash/map"
import queryString from "query-string"
import withLocation from "../containers/withLocation"
import useOverview from "../hooks/use-overview"

const formatNumber = (number = 0) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

const FilterControls = (props) => {
  const { navigate, search } = props

  const { filterType: initialType, filter: initialFilter } = search
  
  const overview = useOverview();

  const allPolicies = get(overview, "policies.all") || []
  const allActivities = get(overview, "activities.all") || []
  const allSkills = get(overview, "skills.all") || []
  const populationRanges = get(overview, "population.chunks") || []

  const [filterType, updateFilterType] = useState(initialType || null)

  const [ filters, updateFilters ] = useState({
    policies: initialType ==="policies" && initialFilter ? initialFilter : "all",
    activities: initialType === "activities" && initialFilter ? initialFilter : "all",
    skills: initialType === "skills" && initialFilter ? initialFilter : "all",
    population: initialType === "population" && initialFilter ? initialFilter : "all",
  })

  const handleFilterTypeSelect = (id) => {
    const newId = id === filterType ? null : id
    updateFilterType(newId)
    if (newId) {
      search.filterType = id
      search.filter = filters[id]
      navigate(`/explore?${queryString.stringify(search)}`, { replace: true })
    } else {
      navigate(`/explore`, { replace: true })
    }
  }

  const handleFilterSelect = (id) => {
    updateFilters({ ...filters, [filterType]: id })
    search.filter = id
    navigate(`/explore?${queryString.stringify(search)}`, { replace: true })
  }

  return (
    <Wrapper className="filter-controls-wrapper">
      <div className="inner">
        <div className="upper">
          <FilterButton
            id="policies"
            label="Policy area"
            color="purple"
            isActive={filterType === "policies"}
            onSelect={handleFilterTypeSelect}
          />
          <FilterButton
            id="skills"
            label="Innovation role"
            color="greenBlue"
            isActive={filterType === "skills"}
            onSelect={handleFilterTypeSelect}
          />
          <FilterButton
            id="activities"
            label="Innovation activity"
            color="darkBlue"
            isActive={filterType === "activities"}
            onSelect={handleFilterTypeSelect}
          />
          <FilterButton
            id="population"
            label="Population size"
            color="pink"
            isActive={filterType === "population"}
            onSelect={handleFilterTypeSelect}
          />
        </div>

        <div className={`lower ${filterType ? "show" : ""}`}>
          <div className="options">
            <div style={{ display: filterType === "policies" ? "block" : "none" }}>
              <label
                className="label"
                htmlFor="filter-policies"
              >
                Select a policy area
              </label>
              <select
                name="filter-policies"
                id="filter-policies"
                className="select"
                onChange={e => handleFilterSelect(e.target.value)}
                value={filters["policies"]}
              >
                <option value="all">All</option>
                {map(allPolicies, (policy, i) => (
                  <option
                    key={`policy-option-${i}`}
                    value={policy.id}
                  >
                    {policy.title}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: filterType === "skills" ? "block" : "none" }}>
              <label
                className="label"
                htmlFor="filter-skills"
              >
                Select an innovation role/skill
              </label>
              <select
                name="filter-skills"
                id="filter-skills"
                className="select"
                onChange={e => handleFilterSelect(e.target.value)}
                value={filters["skills"]}
              >
                <option value="all">All</option>
                {map(allSkills, (skill, i) => (
                  <option
                    key={`skill-option-${i}`}
                    value={skill.id}
                  >
                    {skill.title}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: filterType === "activities" ? "block" : "none" }}>
              <label
                className="label"
                htmlFor="filter-activities"
              >
                Select an innovation activity
              </label>
              <select
                name="filter-activities"
                id="filter-activities"
                className="select"
                onChange={e => handleFilterSelect(e.target.value)}
                value={filters["activities"]}
              >
                <option value="all">All</option>
                {map(allActivities, (activity, i) => (
                  <option
                    key={`activity-option-${i}`}
                    value={activity.id}
                  >
                    {activity.title}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: filterType === "population" ? "block" : "none" }}>
              <label
                className="label"
                htmlFor="filter-population"
              >
                Select a population range
              </label>
              <select
                name="filter-population"
                id="filter-population"
                className="select"
                onChange={e => handleFilterSelect(e.target.value)}
                value={filters["population"]}
              >
                <option value="all">All</option>
                {map(populationRanges, (chunk, i) => {
                  return (
                    <option
                      key={`population-option-${i}`}
                      value={i}
                    >
                      {`${formatNumber(chunk[0])} to ${formatNumber(chunk[chunk.length - 1])}`}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background: white;
  box-shadow: 0 2px 10px 0 rgba(98, 101, 110, 0.15);

  @media (min-width: ${({ theme }) => theme.tablet}) {
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0 2px 10px 0 rgba(98, 101, 110, 0.15);
    overflow: hidden;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    max-width: 36.375rem;
  }

  .upper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: white;
    padding: 1.875rem 1.25rem;

    @media (min-width: 420px) {
      flex-direction: row;
    }

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 1.875rem 3.125rem;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      padding: 1.875rem 1.25rem;
    }

    .button {
      width: 100%;
      letter-spacing: 0.35px;
      padding: 0 0.825rem;
      white-space: initial;
      margin: 0.25rem 0;

      @media (min-width: 420px) {
        margin: 0 0.2rem;
      }

      @media (min-width: ${({ theme }) => theme.tablet}) {
        width: auto;
        margin: 0 0.125rem;
      }

      @media (min-width: ${({ theme }) => theme.desktop}) {
        margin: 0;
      }

      &:first-child {
        margin-left: 0;
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      &:hover {
        background: white;
      }

      &.purple {
        color: ${({ theme }) => theme.purple};
        border-color: ${({ theme }) => theme.purple};

        &.active {
          background: ${({ theme }) => theme.purple};
          color: white;
        }

        @media (min-width: ${({ theme }) => theme.mobile}) {
          &:hover, &.active {
            background: ${({ theme }) => theme.purple};
            color: white;
          }
        }
      }

      &.greenBlue {
        color: ${({ theme }) => theme.greenBlue};
        border-color: ${({ theme }) => theme.greenBlue};

        &.active {
          background: ${({ theme }) => theme.greenBlue};
          color: white;
        }

        @media (min-width: ${({ theme }) => theme.mobile}) {
          &:hover, &.active {
            background: ${({ theme }) => theme.greenBlue};
            color: white;
          }
        }
      }

      &.darkBlue {
        color: ${({ theme }) => theme.darkBlue};
        border-color: ${({ theme }) => theme.darkBlue};

        &.active {
          background: ${({ theme }) => theme.darkBlue};
          color: white;
        }

        @media (min-width: ${({ theme }) => theme.mobile}) {
          &:hover, &.active {
            background: ${({ theme }) => theme.darkBlue};
            color: white;
          }
        }
      }

      &.pink {
        color: ${({ theme }) => theme.pink};
        border-color: ${({ theme }) => theme.pink};

        &.active {
          background: ${({ theme }) => theme.pink};
          color: white;
        }

        @media (min-width: ${({ theme }) => theme.mobile}) {
          &:hover, &.active {
            background: ${({ theme }) => theme.pink};
            color: white;
          }
        }
      }
    }
  }

  .lower {
    display: none;
    background: ${({ theme }) => theme.grey3};
    padding: 0.875rem 1.25rem 1.25rem;

    &.show {
      .page-explore & {
        display: block;
      }
    }

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 0.875rem 3.125rem 1.25rem;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      padding: 0.875rem 1.25rem 1.25rem;
    }

    .options {

      .label {
        display: block;
        font-family: ${({ theme }) => theme.fonts['roboto']};
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.71;
        letter-spacing: 0.35px;
        color: ${({ theme }) => theme.grey1};
        margin-bottom: 0.25rem;
      }

      .select {
        width: 100%;
        display: block;
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts['roboto']};
        font-weight: bold;
        color: ${({ theme }) => theme.grey1};
        line-height: 1.3;
        padding: 0.5625rem 1.25rem 0.5625rem 1.25rem;
        border-radius: 20px;
        border: solid 1px #dddee0;
        background-color: #ffffff;
        margin: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%231d212b%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: right 1.25rem top 50%;
        background-size: .65em auto;

        @media (min-width: ${({ theme }) => theme.tablet}) {
          max-width: 88%;
          font-size: 0.875rem;
          padding: 0.5625rem 1.25rem 0.6875rem 1.25rem;
        }
      }
      
      .select::-ms-expand {
        display: none;
      }

      .select:hover {
        border-color: #dddee0;
      }
      
      .select:focus {
        border-color: #dddee0;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
      }
    }
  }
`

const FilterButton = ({ id, label, color, isActive, onSelect }) => (
  <a
    className={`button ${color} ${isActive && "active"}`}
    tabIndex="0"
    onClick={(e) => {
      e.preventDefault()
      onSelect(id)
    }}
    href="/"
  >
    <span>{label}</span>
  </a>
)

export default withLocation(FilterControls)
