import React from "react"
import _get from "lodash/get"
import _map from "lodash/map"
import Map from "./Map"
import useCities from "../hooks/use-cities"

import FilteredCitiesProvider from "../providers/FilteredCitiesProvider"

const CityMap = (props) => {
  const cities = useCities()
  return (
    <FilteredCitiesProvider cities={cities}>
      {({ filteredCities, filter, filterType }) => {
        const markers = _map(filteredCities, (city, i) => {
          const title = _get(city, 'summary.display_name') || city.title
          return {
            slug: city.slug,
            latitude: _get(city, 'latitude'),
            longitude: _get(city, 'longitude'),
            title,
            data: city
          }
        })
          .filter(city => city.latitude && city.longitude)
        return (
          <div className={filterType ? `map-filter--${filterType}` : ""}>
            <Map
              showControls={true}
              markers={markers}
              {...props}
              filter={filter}
              filterType={filterType}
              resetOnFilterChange={true}
            />
          </div>
        )
      }}
    </FilteredCitiesProvider>
  )
}

export default CityMap
