import React, { 
  useState,
  createRef,
  useEffect,
  useRef
} from "react"
import ReactMapGL, {
  NavigationControl,
  Marker,
  Popup as MapPopup,
  FlyToInterpolator 
} from "react-map-gl"
import styled, { css } from "styled-components"
import get from "lodash/get"
import includes from "lodash/includes"
import LocationPin from "./styled-components/LocationPin"
import CityMapPopup from "./CityMapPopup"
import { easeCubic } from "d3-ease"
import Cluster from "./Cluster"
import ClusterPin from "./ClusterPin"

const DEFAULTS = {
  latitude: 24.71501161210432,
  longitude: 4.574002955150588,
  zoom: 0.6657416994187944,
  mobile: {
    latitude: 2.931360569943101,
    longitude: 0,
    zoom: 0,
  }
}
const FLY_TIME = 500
const TOKEN = process.env.GATSBY_MAPBOX_API_TOKEN
const MAP_STYLES = process.env.GATSBY_MAPBOX_STYLES

const transitionSettings = {
  transitionDuration: FLY_TIME,
  transitionInterpolator: new FlyToInterpolator(),
  transitionEasing: easeCubic
}

function usePrevFilters (filters) {
  const ref = useRef(filters)
  useEffect(() => {
    ref.current = filters
  }, [ref, filters])
  return ref.current
}

const Map = ({
  showControls = true,
  latitude,
  longitude,
  markers = [],
  initialZoom = DEFAULTS.zoom,
  filter,
  filterType,
  resetOnFilterChange = false
}) => {
  let windowWidth = 1200
  if(typeof window !== "undefined") {
    windowWidth = get(window, "innerWidth")
  }
  const initialViewport = useRef({
    zoom: windowWidth <= 500 ? DEFAULTS.mobile.zoom : initialZoom,
    latitude: latitude || windowWidth <= 500 ? DEFAULTS.mobile.latitude : DEFAULTS.latitude,
    longitude: longitude || windowWidth <= 500 ? DEFAULTS.mobile.longitude : DEFAULTS.longitude,
  })
  const [viewport, updateViewport] = useState(initialViewport.current)
  const [popup, updatePopup] = useState(null)
  const clearPopup = () => updatePopup(null)
  const [map, updateMap] = useState(null)
  const mapRef = createRef()
  const [canZoom, updateCanZoom] = useState(false)

  const prevFilters = usePrevFilters({ filter, filterType })
  useEffect(() => {
    if (filter !== "all" && prevFilters.filter !== filter && resetOnFilterChange) {
      updateViewport({
        ...initialViewport.current,
        ...transitionSettings
      })
      clearPopup()
    }
  }, [filter, filterType, prevFilters, initialViewport, resetOnFilterChange])

  const viewportRef = useRef(viewport)
  const popupRef = useRef(popup)
  useEffect(() => {
    viewportRef.current = viewport
    if(popup) {
      if (!includes(markers.map(({ slug }) => slug ), popup.slug)) {
        clearPopup()
      }
    }
    popupRef.current = popup
  }, [viewport, markers, popup])

  const goToPoint = ({ lat, lon, zoom = 3 }) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
    updateViewport({
      ...viewportRef.current,
      longitude: lon,
      latitude: lat,
      zoom,
      ...transitionSettings
    });
  }

  const setMapEngaged = () => {
    if(map) document.body.classList.add("map-engaged")
  }

  return (
    <MapWrapper
      showControls={showControls}
      onClick={() => !canZoom && updateCanZoom(true)}
      zoom={viewport.zoom}
    >
      <ReactMapGL
        {...viewport }
        ref={mapRef}
        width="100%"
        height="100%"
        dragRotate={false}
        dragPan={showControls}
        mapboxApiAccessToken={TOKEN}
        mapStyle={`${MAP_STYLES}?optimize=true`}
        scrollZoom={canZoom && showControls && viewport.zoom > DEFAULTS.zoom}
        onLoad={() => updateMap(mapRef.current.getMap())}
        onViewportChange={(newViewport) => updateViewport(newViewport)}
        onWheel={() => clearPopup()}
      >
        <div className="map-controls" onClick={() => {
          clearPopup()
          setMapEngaged()
        }}>
          <NavigationControl captureScroll={false} dragZoom={true} showCompass={false} />
        </div>
        {map && (
          <Cluster
            map={map}
            radius={55}
            extent={512}
            nodeSize={40}
            element={clusterProps => (
              <ClusterPin
                {...clusterProps}
                handleClick={({ id, geometry }, superCluster) => {
                  setMapEngaged()
                  const zoom = superCluster.getClusterExpansionZoom(id)
                  const [lon, lat] = geometry.coordinates
                  clearPopup()
                  goToPoint({ lat, lon, zoom: zoom + 0.5 })
                }}
              />
            )}
          >
            {markers.map(({
              slug,
              latitude,
              longitude,
              title = "",
              data
            }, i) => (
              <Marker
                key={`marker-${slug}`}
                latitude={latitude}
                longitude={longitude}
                offsetLeft={-12}
                offsetTop={-35}
              >
                <LocationPin
                  onClick={() => {
                    setMapEngaged()
                    clearPopup()
                    if(popupRef.current) {
                      if(popupRef.current.slug === slug) return
                    }
                    const currentZoom = viewportRef.current.zoom
                    const zoom = currentZoom > initialZoom ? currentZoom : 3
                    goToPoint({ lat: latitude, lon: longitude, zoom })
                    setTimeout(() => {
                      updatePopup({
                        latitude,
                        longitude,
                        title,
                        slug,
                        data
                      })
                    }, FLY_TIME)
                  }} />
              </Marker>
            ))}
          </Cluster>
        )}
        {popup && showControls && (
          <MapPopup
            latitude={popup.latitude}
            longitude={popup.longitude}
            closeButton={true}
            offsetTop={-30}
            closeOnClick={false}
            onClose={() => clearPopup()}
            anchor="bottom-left"
          >
            <CityMapPopup city={popup.data} />
          </MapPopup>
        )}
      </ReactMapGL>
      <div className={`loading-screen ${!map ? "show" : ""}`} />
    </MapWrapper>
  )
}

const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > div {
    touch-action ${({ zoom }) => zoom > 0 ? "none" : "pan-y"} !important;
  }

  .map-controls {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .mapboxgl-ctrl-group {
    display: inline-block;
    background: ${({ theme }) => theme.darkBlue };
    box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.15);
    margin: 0 0.5rem 2.5em 0;
    border-radius: 12px;

    .mapboxgl-ctrl-icon {
      width: 50px;
      height: 50px;
      border-color: white;
    }
    
    ${({ showControls }) => !showControls && css`
      display: none;
    `}
    
    @media (min-width: 999px) {
      margin: 0 3.125rem 2em 0;
    }

    .mapboxgl-ctrl-icon {
      border-color: white;
    }
  }

  .mapboxgl-ctrl-zoom-in {
    background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23ffffff%3B%27%20d%3D%27M%2010%206%20C%209.446%206%209%206.4459904%209%207%20L%209%209%20L%207%209%20C%206.446%209%206%209.446%206%2010%20C%206%2010.554%206.446%2011%207%2011%20L%209%2011%20L%209%2013%20C%209%2013.55401%209.446%2014%2010%2014%20C%2010.554%2014%2011%2013.55401%2011%2013%20L%2011%2011%20L%2013%2011%20C%2013.554%2011%2014%2010.554%2014%2010%20C%2014%209.446%2013.554%209%2013%209%20L%2011%209%20L%2011%207%20C%2011%206.4459904%2010.554%206%2010%206%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
  }

  .mapboxgl-ctrl-zoom-out {
    background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23ffffff%3B%27%20d%3D%27m%207%2C9%20c%20-0.554%2C0%20-1%2C0.446%20-1%2C1%200%2C0.554%200.446%2C1%201%2C1%20l%206%2C0%20c%200.554%2C0%201%2C-0.446%201%2C-1%200%2C-0.554%20-0.446%2C-1%20-1%2C-1%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
  }

  body.is-ie & {
    .mapboxgl-ctrl-zoom-in {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath style='fill:%23FFFFFF' d='M 10 6 C 9.446 6 9 6.4459904 9 7 L 9 9 L 7 9 C 6.446 9 6 9.446 6 10 C 6 10.554 6.446 11 7 11 L 9 11 L 9 13 C 9 13.55401 9.446 14 10 14 C 10.554 14 11 13.55401 11 13 L 11 11 L 13 11 C 13.554 11 14 10.554 14 10 C 14 9.446 13.554 9 13 9 L 11 9 L 11 7 C 11 6.4459904 10.554 6 10 6 z'/%3E%3C/svg%3E") !important;
    }
    .mapboxgl-ctrl-zoom-out {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath style='fill:%23FFFFFF' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E%3C/svg%3E") !important;
    }
  }

  .mapboxgl-popup-content {
    position: relative;
    background: transparent;
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
    pointer-events: auto;
  }

  .mapboxgl-popup-close-button {
    color: white;
  }

  .loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 5rem;
    color: white;
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
    background: #a0dedf url(/img/spinner.gif) no-repeat center center;
    &.show {
      opacity: 1;
    }
  }
`

export default Map
